import Booking from './booking'
import Helpers from './helpers'
import bookingWithAssistanceRequest from 'request/my/booking-with-assistances'
import bookingWithAssistanceUpdate from 'request/my/booking-with-assistance-update'
import bookingWithAssistanceCancel from 'request/my/booking-with-assistance-cancel'
import colleaguesRequest from 'request/my/colleagues'

export default function BookingAssistanceDetail() {
  const { isAdmin } = useCurrent()
  const { state, mutate } = useStatic({
    visible: false,
    booking: null,
    studioStylists: [],
    helpers: [],
    readOnly: false,
    callback: null,
    assistanceList: []
  })
  const t = useI18n()

  h.openBookingAssistanceDetail = async(bookingId, callback) => {
    const { booking, helpers, bookingAssistanceConfig, studioId } = await bookingWithAssistanceRequest(bookingId)
    const { stylists } = await colleaguesRequest(studioId)
    state.booking = booking
    const bookingStylistId = booking.stylist.id
    state.studioStylists = stylists.filter(stylist => stylist.value !== bookingStylistId)
    state.isReadOnly = !!booking.assistanceAt
    state.visible = true
    state.callback = callback
    state.helpers = helpers
    state.assistanceList = bookingAssistanceConfig.assistanceList
    mutate()

    if (helpers.length === 0) { onHelperAdd() }
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const onHelperAdd = () => {
    state.helpers.push({
      stylistId: undefined,
      serviceName: undefined,
      points: 0,
      amount: 0
    })
    mutate()
  }

  const onSave = async() => {
    if (!!state.helpers.find(({ stylistId }) => !stylistId)) return $alert(t('page:bookingAssistances.noAssistant'), 'error')
    if (!!state.helpers.find(({ points }) => !points)) return $alert(t('page:bookingAssistances.noServiceName'), 'error')

    const assistances = state.helpers.map(({ stylistId, serviceName }) => ({
      stylistId,
      serviceName
    }))

    await bookingWithAssistanceUpdate({ bookingId: state.booking.id, assistances })
    closePanel()
    $alert(t('common:alert.submitSuccess'))
    if (state.callback) state.callback()
  }

  const onCancel = async() => {
    if (!confirm('確定要取消互助?')) return
    await bookingWithAssistanceCancel({ bookingId: state.booking.id })
    closePanel()
    $alert(t('common:alert.submitSuccess'))
    if (state.callback) state.callback()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body onHelperAdd={ onHelperAdd } state={ state } mutate={ mutate }/>
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
    >
      <C.ModalHeader title={ t('page:bookingAssistances.detail') }>
        { renderBody() }
        <div className='relative'><C.Blur/></div>
        <div className= { cn({ hidden: state.isReadOnly }, 'shrink-0 px-5 py-1') }>
          <C.Button uid='bookingassistance.save' fullWidth onClick={ onSave }>
            { t('common:submit') }
          </C.Button>
          <div className='text-gray-400 text-center text-sm p-2'>{ t('page:bookingAssistances.systemRemind') }</div>
        </div>
        { isAdmin && (
          <div className= { cn({ hidden: !state.isReadOnly }, 'shrink-0 px-5 pb-5') }>
            <C.Button uid='bookingassistance.cancel' fullWidth onClick={ onCancel }>
              { t('page:bookingAssistances.cancel') }
            </C.Button>
          </div>
        )}
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = parent => {
  const { state, mutate, onHelperAdd } = parent

  return (
    <div className='pb-8 overflow-scroll mt-0.5'>
      { !!state.booking && <Booking { ...state.booking }/> }
      <Helpers
        state={ state }
        mutate={ mutate }
        onHelperAdd={ onHelperAdd }
      />
    </div>
  )
}
