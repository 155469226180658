export default function Helpers({ state, mutate, onHelperAdd }) {
  const t = useI18n()

  return (
    <div>
      <div className='py-2 px-4 flex space-x-4 bg-gray-100'>
        <div className='font-medium'>{ t('page:bookingAssistances.assistant') }</div>
      </div>
      { !!state.helpers.length && <div className='flex flex-col space-y-6 py-6'>
        {
          state.helpers?.map((helper, index) => <Helper key={ index }
            helper={ helper }
            disabled={ state.isReadOnly }
            state={ state }
            mutate={ mutate }
            t={ t }
          />
          )
        }
      </div>
      }
      { !state.helpers.length && <div className='text-gray-400 text-center text-sm p-5'>{ t('page:bookingAssistances.noAssistant') }</div> }
      <div className={ cn('cursor-pointer  border-t border-gray-200 text-gray-400 py-4 mx-4 text-center', { hidden: state.isReadOnly }) } onClick={ onHelperAdd }>{t('page:bookingAssistances.addAssistant')}</div>
    </div>
  )
}

const Helper = ({ state, mutate, helper, disabled, t }) => {
  const { assistanceList, studioStylists } = state
  const form = useForm({ services: [] })

  useEffect(() => {
    if (disabled) return

    if (!form.services.value) {
      const dic = assistanceList.keyBy('name')
      form.updateValues({ services: helper.serviceName?.split('、').map(value => ({ id: value, key: value, value, point: dic[value].point })) || undefined })
      return
    }
    helper.serviceName = form.services.value.map(({ value }) => value).join('、')
    helper.points = form.services.value.sum(({ point }) => point)
    mutate()
  }, [form.services.value?.length])

  const onHelperChange = stylistId => {
    helper.stylistId = stylistId
    mutate()
  }

  const onDelete = () => {
    state.helpers.splice(state.helpers.indexOf(helper), 1)
    mutate()
  }

  const serviceLoadMore = ({ keyword }) => {
    const substring = keyword.trim().toLowerCase()
    return assistanceList.filter(({ name }) => name.toLowerCase().includes(substring))
  }

  const amountResolver = () => {
    if (!disabled) return
    if (!helper.amount) return
    return <div className='text-gray-500'>{ t('page:bookingAssistances.amount', { amount: helper.amount.currency() }) }</div>
  }

  return (
    <div className='px-4 relative'>
      <div className='w-full border p-4 shadow-md rounded-md'>
        <div className='space-y-5'>
          <div className='flex-1'>
            <C.Select
              label={ t('page:bookingAssistances.assistant') }
              value={ helper.stylistId || '' }
              options={ studioStylists }
              className='w-full'
              disabled={ disabled }
              onChange={ onHelperChange }
            />
          </div>
          <div className='flex-1'>
            { disabled && <div className='w-full pb-5 text-gray-500'>協助項目: { helper.serviceName }</div> }
            {!disabled && <C.AsyncSelectForm
              label={ t('page:bookingAssistances.addServiceItem') }
              form={ form }
              multiple
              field='services'
              optionFormat={ option => ({
                id: option.name,
                key: option.name,
                value: option.name,
                ...option
              }) }
              optionRender={ option => (
                <div className='flex justify-between flex-1'>
                  <div className='truncate ml-2'>{ option.value } / { option.point }點</div>
                </div>
              ) }
              loadMore={ serviceLoadMore }
            />
            }
          </div>
        </div>

        <div className='flex space-x-2 border-t pt-3'>
          <div className='flex-1'/>
          <div>{ t('page:bookingAssistances.totalPoint', { point: helper.points }) }</div>
          { amountResolver() }
        </div>

      </div>
      <CloseButton className={ cn('absolute top-[-6px] right-3 bg-gray-600 text-white w-8 h-8 rounded-full', { hidden: disabled }) } onClick={ onDelete }/>
    </div>
  )
}

const CloseButton = ({ onClick, className }) =>
  <div className={ cn('cursor-pointer', className) } onClick={ onClick }>
    <Icon.Close className='m-1'/>
  </div>
