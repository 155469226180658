export default function Booking({ startTime, stylist, customer, serviceNames, realPayment }) {
  const serviceName = serviceNames.join('、')

  return (
    <div>
      <div className='py-2 px-4 bg-gray-100 font-medium'>預約明細</div>
      <div className='flex flex-col space-y-2 py-2 px-4'>
        <BookingField label= '時間' value={ dayjs(startTime).ymdhm() }/>
        <BookingField label= '設計師' value={ stylist.user.name }/>
        <BookingField label= '顧客' value={ customer.name }/>
        <BookingField label= '服務' value={ serviceName }/>
        <BookingField label= '金額' value={ realPayment.currency() }/>
      </div>
    </div>
  )
}

const BookingField = ({ label, value }) => (
  <div className='flex justify-between space-x-11 text-gray-700'>
    <div className='font-bold shrink-0'>{label}</div>
    <div>{value}</div>
  </div>
)