const template = `
  query ($id: ID!) {
    bookingAssistanceConfig {
      assistanceList {
        name
        point
      }
    }
    booking(id: $id) {
      id
      startTime
      realPayment
      assistanceAt
      assistanceQueueAt
      studioId
      stylist {
        id
        user {
          name
        }
      }
      services {
        service {
          name
        }
      }
      customer {
        name
      }
      bookingAssistances {
        name
        stylist {
          id
          user {
            name
          }
        }
        amount
        points
      }
    }
  }
`

export default async function bookingWithAssistances(id) {
  const { booking, bookingAssistanceConfig } = await request(template, { id })

  const _booking = {
    ...booking,
    customerName: booking.customer.name,
    serviceNames: booking.services.map(service => service.service.name)
  }
  return {
    helpers: feedHelpers(booking),
    bookingAssistanceConfig,
    booking: _booking,
    studioId: booking.studioId
  }
}

const feedHelpers = booking => booking.bookingAssistances.map(ba => ({
  stylistId: ba.stylist.id,
  serviceName: ba.name,
  points: ba.points,
  amount: ba.amount
})) || []
