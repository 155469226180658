const template = `
  query ($studioId: ID!) {
    me {
      stylist {
        id
      }
    }
    studio(id: $studioId) {
      stylistMemberships {
        isAssistant
        stylist {
          id
          user {
            name
          }
        }
      }
    }
  }
`

export default async function colleagues(studioId) {
  const { me, studio } = await request(template, { studioId })
  const stylists = feedStylists(me, studio)
  return { stylists }
}

const feedStylists = (me, studio) => {
  const options = studio.stylistMemberships.sortBy(member => member.isAssistant)
    .map(member => ({
      value: member.stylist.id,
      name: member.stylist.user.name,
      text: member.stylist.user.name
    }))
  return [...options]
}
